.card {
  width: 90%;
  height: 80%;
  place-self: center;
  background-color: var(--Snow);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  grid-template-areas: "txt img";
  border-radius: 10px;
  padding: 20px;
  filter: drop-shadow(-2px 2px 4px #0000002c);
  overflow: hidden;
}

.imgContainer {
  grid-area: img;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgContainer img {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
}

.info {
  grid-area: txt;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 10px;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description h4 {
  font-size: 0.9em;
  margin: 0;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.details strong {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 300;
  color: #ff0000;
  margin: 0;
  align-self: self-start;
}

.price {
  font-weight: 700;
  font-size: 24px;
  color: var(--Middle-Blue-Green);
  margin: 0;
  align-self: self-start;
}

.price::before {
  content: "$";
}

@media (max-width: 768px) {
  .card {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "img" "txt";
    height: auto;
    padding: 10px;
  }

  .info {
    grid-template-rows: auto auto;
  }
}
