.skeleton {
    background-color: #f2f2f2;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    animation: skeleton-pulse 1s ease-in-out infinite;
  }
  
  .skeleton-line {
    background-color: #e0e0e0;
    height: 20px;
    width: 100%;
    margin-bottom: 8px;
    border-radius: 4px;
  }
  
  .skeleton-line:last-child {
    margin-bottom: 0;
    width: 80%;
  }
  
  @keyframes skeleton-pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }
  