:root{
    --Eerie-Black: #131515;
    --Eerie-Black-Alpha: #1315151a;
    --Jet: #2b2c28;
    --Jet-Alpha: #2b2c281a;
    --Persian-Green:#339989;
    --Persian-Dark-Green:#32665e;
    --Persian-Green-Alpha:#3399891a;
    --Middle-Blue-Green: #7de2d1;
    --Middle-Blue-Green-Alpha: #7de2d11a;
    --Snow: #fffafb;
    --Snow--Alpha: #fffafb1a;
}