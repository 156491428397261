.items {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;
    margin-bottom: 50px;
    position: relative;
    padding: 50px;
  }