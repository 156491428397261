.container {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
  }
  
  .container span {
    text-align: center;
    margin: 10px;
  }
  
  .content {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .content {
      padding: 10px;
    }
  }
  