.slider {
  width: 90%;
  height: 90%;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
  background-color: var(--Persian-Green);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  border-radius: 10px;
}

.slider__img {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.slider__img img {
  all: unset;
  aspect-ratio: 254 / 254;
  width: 100%;
  position: absolute;
  z-index: 100;
  filter: drop-shadow(30px 10px 4px #0000002c);
}

.background {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(100px);
  background-color: var(--Snow);
  opacity: .5;
}

.slider__callToAction {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
}

.slider strong {
  font-size: 3em;
  color: var(--Snow);
  word-wrap: break-word;
}

.slider__button {
  background-color: var(--Snow);
  padding: 20px 50px;
  border: none;
  border-radius: 50px;
  color: var(--Persian-Green);
  text-transform: uppercase;
  font-weight: 700;
  transition: all .5s ease-out;
}

.slider__button:hover {
  transition: all .5s ease-out;
  padding: 22px 52px;
  cursor: pointer;
  background-color: var(--Persian-Green);
  border: 3px solid var(--Snow);
  color: var(--Snow);
}

@media (max-width: 768px) {
  .slider {
    flex-direction: column;
    height: 60%;
    padding: 10px;
  }

  .slider__img {
    width: 40%;
    height: auto;
    margin-bottom: 20px;
  }

  .slider__img img {
    top: -120px;
    left: 80%;
  }

  .slider__callToAction {
    width: 100%;
    height: 90%;
    padding: 20px;
  }

  .slider strong {
    font-size: 1.2em;
  }

  .slider__button {
    padding: 10px 20px;
  }
}
