.container{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content{
    width: 90%;
    height: 80vh;
    border-radius: 15px;
    background-color: var(--Persian-Green-Alpha);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.content h2{
    color: var(--Persian-Dark-Green);
}

.details div span{
    font-weight: 700;
    color: var(--Persian-Dark-Green);
}

.details div strong{
    font-size: 16px;
    color: var(--Persian-Dark-Green);
    font-weight: 400;
}

.coupons{
    width: 90%;
    height: max-content;
    padding: 10px;
    border-radius: 15px 15px 0 0;
    background-color: var(--Persian-Green-Alpha);
}

.form{
    width: 100%;
    height: 100%;
}

.details{
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: column;
}

.dt1, .dt2, .dt3, .dt4{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    background-color: var(--Persian-Green-Alpha);
    margin-bottom: 10px;
    padding: 5px;
} 
.dt4{
    border-radius: 0 0 15px 15px;
}

.btns{
    width: 100%;
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-rows: 100%;
    grid-template-areas: "inp btn";
}

.coupons input{
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 15px 0 0 15px;
    background-color: var(--Snow);
    padding: 10px;
    grid-area: inp;
}

.addButton{
    grid-area: btn;
    border-radius: 0 15px 15px 0;
    border: none;
    background-color: var(--Persian-Green);
    color: var(--Snow);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
}

.container span{
    font-weight: 700;
    color: var(--Persian-Green);
}

.checkOutBtn{
    background-color: var(--Persian-Green);
    border: none;
    border-radius: 15px;
    width: 90%;
    height: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--Snow);
    cursor: pointer;
}

@media (max-width: 768px) {
    .container{
        width: 100%;
    }
}