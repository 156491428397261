.container{
    width: max-content;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    padding: 5px;
    gap: 10px;
    background-color: transparent;
}

.container:hover{
    cursor: pointer;
    background-color: var(--Persian-Green-Alpha);
}

.container:active{
    background-color: var(--Middle-Blue-Green-Alpha);
}

.MdShCart, .MdFav, .MdLogin{
    font-size: 24px;
    color: var(--Persian-Green);
}