.container{
    width: 100%;
    height: 300px;
    margin-top: 50px;
    background-color: var(--Persian-Green);
    display: grid;
    grid-template-columns: 25% 50% 25%;
    overflow: hidden;
}

.container :where(h4, p, a, span){
    color: var(--Snow);
    margin: 5px;
}

.support{
    width: 100%;
    height: 100%;
    grid-column: 0 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.support p{
    font-size: 12px;
}

.listContainer{
    grid-column: 2 / 3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.listContainer nav ul li{
    font-size: 14px;
}

.newsletter{
    grid-column: 3 / 4;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}