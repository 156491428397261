:root{
  --header-height: 100px;
  --header-info-height: 30px;
  --header-navcat-height:40px;
}

.header a{
  color: var(--Jet);
}

.header{
  width: 100%;
  height: var(--header-height);
  background-color: var(--Snow);
  border-bottom: 1px solid var(--Persian-Green-Alpha);
  display: flex;
  align-items: center;
}

.MdMenu{
  font-size: 32px;
  cursor: pointer;
  color: var(--Persian-Green);
}

.MdMenu:active{
  color: var(--Persian-Green-Alpha);
}

.categ__menu{
  width: max-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px
}

.logo{
  width: 200px;
  height: 150px;
}

.logo img{
  width: 100%;
  height: 100%;
  mix-blend-mode: darken;
  aspect-ratio: 2 / 3;
}

.search{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search__box{
  width: 80%;
  height: 45px;
  background-color: #e4e4e4;border-radius: 5px;
  display: flex;
}

.search input{
  width: 60%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent; 
  padding: 5px;
  grid-area: box;
}

.search select{
  width: 30%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  padding: 5px;
  grid-area: select;
}

.MdSearch{
  width: 10%;
  height: 100%;
  border-left: 2px solid var(--Eerie-Black-Alpha);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  grid-area: btn;
}

.MdSearch a{
  width: 100%;
  height: 100%;
  font-size: 24px;
  color: var(--Persian-Green);
  display: flex;
  justify-content: center;
  align-items: center;
}

.MdSearch:active{
  background-color: var(--Persian-Green-Alpha);
}

.search__box select::after{
content: '|';
}

.headerItems{
  width: 30%;
  display: flex;
  justify-content: space-evenly;
}
  
  @media (max-width: 768px) {
    .header {
      padding: 10px;
    }
  
    .header__info {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .search {
      width: 100%;
      margin-top: 10px;
      justify-content: flex-end;
    }
  
    .search__box {
      width: 100%;
      padding: 0 10px;
    }
  
    .headerItems {
      width: 100%;
      justify-content: space-between;
    }
    .headerItems span{
      display: none;
    }
  }
  