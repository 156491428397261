.itemImage{
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.circle{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: white;
    overflow: hidden;
    outline: 5px solid rgba(128, 128, 128, 0.205);
    cursor: pointer;
}

.circle img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.itemImage span{
    cursor: pointer;
}