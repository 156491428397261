* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-family: 'Poppins', serif;
    text-decoration: none;
  }
  
  body {
    background-color: #f4f4f4;
  }
  
  #root {
    width: 100%;
    height: 100vh;
  }
  
  .promoContainer {
    width: 100%;
    height: 400px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 50% 50%;
  }
  
  @media (max-width: 768px) {
    .promoContainer {
      height: auto;
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  }
  
  @media (max-width: 480px) {
    .promoContainer {
      grid-template-columns: 100%;
    }
  }
  