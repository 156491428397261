.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  width: 80%;
  height: 80% !important;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-areas: "imgC detailsC";
}

.imageContainer {
  grid-area: imgC;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0 0px 15px;
  overflow: hidden;
  background-color: var(--Persian-Green-Alpha);
  box-shadow: inset -50px 0px 60px 0px rgba(0, 0, 0, 0.096);
}

.imageContainer img {
  width: 50%;
  max-width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  filter: drop-shadow(0 -6mm 20mm #ffffff);
}

.detailsContainer {
  grid-area: detailsC;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  color: var(--Jet);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detailsContainer h1 {
  font-size: 36px;
  margin: 0;
}

.detailsContainer p {
  font-size: 18px;
  margin: 0;
}

.price {
  font-size: 18px;
  font-weight: 600;
}

.specs {
  width: 100%;
}

.specs ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.specs li {
  text-align: center;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--Persian-Green);
  font-weight: 700;
  color: var(--Snow);
}

.quantitySetter {
  width: 100px;
  height: 50px;
  background-color: var(--Persian-Green-Alpha);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
}

.lessBtn,
.moreBtn {
  width: 33.3%;
  height: 100%;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.lessBtn:active,
.moreBtn:active {
  color: var(--Middle-Blue-Green);
}

.actionBtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.buyNow {
  padding: 15px 30px;
  border-radius: 15px;
  border: none;
  background-color: var(--Persian-Green);
  color: var(--Snow);
}

.addToC {
  padding: 10px 25px;
  border-radius: 15px;
  border: 2px solid var(--Persian-Green);
  color: var(--Persian-Green);
}

@media (max-width: 768px) {
  .form {
    grid-template-columns: 100%;
    grid-template-areas:
      "imgC"
      "detailsC";
  }

  .imageContainer {
    border-radius: 15px 15px 0 0;
  }

  .detailsContainer {
    padding: 20px;
  }
}
