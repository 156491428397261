.container {
  min-width: 100px;
  width: 70%;
  height: 100%;
  background-color: #f4f4f4;
  border-right: 3px solid var(--Eerie-Black-Alpha);
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container{
    border: none;
    width: 95%;
  }
  
}