.container{
    width: 100px;
    height: 100px;
}

.imageContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imageContainer img{
    width: 80%;
    filter: brightness(-100%);
    aspect-ratio: 4 / 2;
    object-fit: contain;
}