.container {
  width: 100%;
  height: 100%;
  display: flex;
}

@media (max-width: 768px) {
    .container {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 70% 30%;
      grid-template-areas:
        'products'
        'total';
        place-items: center;
    }
  }
  