.container{
    width: 90%;
    height: 90%;
    grid-column: 3 / 4;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.infoContainer{
    width: 90%;
}

.inputContainer{
    width: 90%;
    height: 40px;
    display: grid;
    grid-template-columns: 70% 30%;
}

.inputContainer :where(input, button){
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

.inputContainer input{
    padding: 5px;
}

.inputContainer button{
    background-color: var(--Middle-Blue-Green);
    color: var(--Jet);
}

.policyContainer{
    font-size: 12px;
    width: 90%;
}