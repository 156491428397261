.nav__categories {
  width: max-content;
  height: 100vh;
  position: absolute;
  padding: 10px;
  transition: all .5s ease-out;
  background-color: var(--Persian-Green);
  animation: navcat .5s forwards 0s ease-out alternate;
  z-index: 200;
}

@keyframes navcat {
  0%{
    opacity: 0;
  }
  50%{
    opacity: .5;
  }
  100%{
    opacity: 1;
  }
  
}

.nav__categories ul{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  
}

.nav__categories ul li {
  margin: 10px;
}

.nav__categories ul li a {
  color: var(--Snow);
}
