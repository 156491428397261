.items {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 10px;
    margin-bottom: 50px;
    position: relative;
    padding-bottom: 50px; 
  }
  
  .items .showMore {
    padding: 10px 20px;
    background-color: var(--Persian-Green);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .items .showMore:hover {
    background-color: var(--Middle-Blue-Green);
    color: var(--Persian-Green);
  }
  
  .productsQuantity {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
  }